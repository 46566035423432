<template>
    <div class="bo">
        <div class="name">
            <img src="../../assets/img/Slice35@2x.png" />
            <span>{{ name }}</span>
        </div>
        <div class="uploader">
            <p>上传人脸照片</p>
            <van-uploader v-model="fileList" multiple :max-count="1" :after-read="afterread" />
        </div>
        <div class="msg">
            <div class="left">
                <div>
                    <img src="../../assets/img/Slice58@2x.png">
                    <span>标准人脸图展示</span>
                </div>
                <div>
                    <img src="../../assets/img/zhenjianzhao.png">
                </div>
            </div>
            <div class="right">
                <div>
                    <img src="../../assets/img/Slice158@2x.png">
                    <span>标准人脸图展示</span>
                </div>
                <div>
                    <img src="../../assets/img/zi.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { edit_student, upload, edit_info } from '@/api/pickup/list.js'
import { getUserId } from "../../utils/user";
import { Notify } from 'vant';
export default {
    data() {
        return {
            fileList: [
                { url: '' }
            ],
            err: '../../assets/img/nohead.png',
            id: '',
            data: {
                student_id: '',
                face: ''
            },
            name: '',
            oss: process.env.VUE_APP_OSS_URL,
        };
    },
    created() {
        this.id = getUserId();
        this.getinfo()
    },
    methods: {
        getinfo() {
            edit_info(this.id).then(res => {
                this.data.student_id = res.data.id
                this.data.face = res.data.face
                this.fileList[0].url = this.oss + res.data.face
                console.log(this.fileList);
                this.name = res.data.user_name

                this.checkImg(this.fileList[0].url)
            })
        },

        // 上传图片
        async afterread(file) {
            var f = await this.$api.compressImg(file.file)
            var formdata = new FormData()
            formdata.append('image', f)
            upload(formdata).then(res => {
                if (res.code == 200) {
                    this.fileList[0].url = ''
                    this.fileList[0].url = res.data.url
                    this.data.face = res.data.url
                    edit_student(this.data).then(res => {
                        console.log(res);
                        if (res.code == 200) {
                            Notify({ type: 'success', message: '上传成功' });
                            setTimeout(() => { history.go(-1) }, 1000)
                        } else {
                            Notify({ type: 'error', message: res.msg });
                        }
                    })
                }
            })
        },

        checkImg(imgurl) {
            return new Promise(function (resolve, reject) {
                var imgObj = new Image();
                imgObj.src = imgurl;
                imgObj.onload = function (res) {
                    resolve(res);
                }
                imgObj.onerror = function (err) {
                    reject(err)
                }
            }).then(() => {

                console.log('有效链接')
            }).catch(() => {

                console.log('无效链接')
                this.fileList[0].url = 'kindergarten/photo/20230913/ba769fefd7b89f75c591d116997a6f6a0005e374.png'
            })
        },


    }
};
</script>

<style lang="less" scoped src="./css/s_details.less"></style>
